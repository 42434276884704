'use client';

import { Roles } from '@deltasierra/frontend/graphql';
import { useGlobalData } from './useGlobalData';

export function useHasAllRoles(
    rolesThatCanAccess: Roles[],
): boolean {
    const {
        me: { roles: userRoles },
    } = useGlobalData();

    return rolesThatCanAccess.every(allowedRole => userRoles.includes(allowedRole));
}
