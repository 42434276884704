'use client';

import { useSuspenseQuery } from '@apollo/client';
import {
    GET_CLIENT_OR_NULL_CHECK,
    GetClientOrNullCheckQuery,
    GetClientOrNullCheckQueryVariables,
} from '@deltasierra/frontend/graphql';

export function useCanAccessClient(clientId: string): boolean {
    const { data } = useSuspenseQuery<GetClientOrNullCheckQuery, GetClientOrNullCheckQueryVariables>(
        GET_CLIENT_OR_NULL_CHECK,
        {
            variables: {
                clientId,
            },
        },
    );

    return !!data.client;
}
