'use client';

import { RedirectErrorHomepage } from '@deltasierra/frontend/errors';
import { Roles } from '@deltasierra/frontend/graphql';
import { useCanAccessClient } from './useCanAccessClient';
import { useHasSomeRoles } from './useHasSomeRoles';

export function useCanAccessAgencyUserClientPageOrRedirect(clientId: string): void {
    const hasClientAccess = useCanAccessClient(clientId);
    const hasAccessToPage = useHasSomeRoles([Roles.Admin, Roles.Manager]);

    if (!hasClientAccess || !hasAccessToPage) {
        throw new RedirectErrorHomepage();
    }
}
