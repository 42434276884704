'use client';

import { Roles } from '@deltasierra/frontend/graphql';
import { useGlobalData } from './useGlobalData';

export function useHasSomeRoles(
    rolesThatCanAccess: Roles[],
): boolean {
    const {
        me: { roles: userRoles },
    } = useGlobalData();

    return rolesThatCanAccess.some(allowedRole => userRoles.includes(allowedRole));
}
